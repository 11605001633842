.section-row {
    margin: 80px 0 0 0;
}
*{
    font-family: "Bookman Old Style";
}
.recharts-responsive-container {
    height: 300px !important;
}
.slider-img {
    width: 220px;
    margin: 0 auto;
}
.row-carusel {
    padding: 3rem 0 3rem 0;
    background-color: #C0FF95;
    color: #209c2d;
}
.li-1{
font-size: 16px;
    font-family:sans-serif;
}
.border-icon{
    width: 90px;
    height: 90px;
    margin: 0 10px;
    font-size: 40px;
    color: white;
    background-color: #209c2d;
    text-align: center;
    border-radius: 50%;

}

.li-p{
    font-size: 8px;
}
.row-1{
    background-color: #c0ff95;
}
.ul-li-p{
    font-size: 16px;
    align-items: center;
}
.icons-1{
    font-size: 40px;
    margin: 20px 0;
    border: 1px solid darkgreen;
    padding: 8px;

}
.icons-1:hover{
    background-color: #209c2d;
    color: white;
    transition: all 0.3s linear;
    cursor: pointer;
}
.home-img-width{
    width: 100%;
    height: 400px;
}
.ul-li{
    width: 90px;
    height: 90px;
    background-color: #209c2d;
    color: white;
    margin: 10px 0;
    border-radius: 50%;
    text-align: center;


}
.tansport-text{
    font-size: 20px;
    text-align: center;
    margin: 25px 0;
    padding: 30px;
font-family: "Bookman Old Style";
}
.video-js .vjs-big-play-button{
background-color: #209c2d ! important;
}
.video-js{
    width: 100%;
    height: 280px;
    border-radius: 20px;
    background-color: #209c2d !important;

}
