.navs {

    background-color: #209c2d;
    position: fixed;
    top: 0;
    left: 0;
    padding: 2px 0 6px 0;

}
*{
    font-family: "Bookman Old Style";
}
.link-hover {
    color: white;
    margin: 5px 20px;
    font-weight: 400;
    font-size: 19px;

}

.link-hover:hover {
    border-bottom: 2px solid white;
    transition: all 0.2s linear;
    color: white;

}
.logo{
    width: 185px;
}
