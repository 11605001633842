.contact-row {

    background-color: #c0ff95;
    color: black;
    padding: 2rem;

}
*{
    font-family: "Bookman Old Style";
}
.contact-ul li {
    color: white;
    font-weight: 600;
    font-size: 20px;
    margin: 15px 0;
}

.contact-ul li a {
    text-decoration: none;
    color: white;
}
.row-2 {
    background-color: #209c2d;
    padding: 4rem;
}