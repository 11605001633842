.footer {
    padding: 20px 80px 20px 80px;
    font-size: 20px;
    background-color: #209c2d;
    border-top: 1px solid gray;


}
*{
    font-family: "Bookman Old Style";
}